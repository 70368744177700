import React, { useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import styles from './styles';
import ScheduleMobileHeader from './components/ScheduleMobileHeader/ScheduleMobileHeader';
import { Grid, Tabs, Tab, Divider } from '@material-ui/core';
import MySchedule from './components/MySchedule/MySchedule';
import Resource from './components/Resource/Resource';
import SnackBar from 'components/SnackBar';
import CarouselCalendar from 'components/CarouselCalendar/CarouselCalendar';
import { useSelector } from 'react-redux';

const TABS = [
  {
    index: 0,
    name: 'schedule',
    label: 'Schedule',
  },
  {
    index: 1,
    name: 'resources',
    label: 'Resources',
  },
];

const AdminMobileSchedulePage = ({ classes }) => {
  const selectedDate = useSelector(state => state.schedule.selectedDate);
  const [activeTab, setActiveTab] = useState(TABS[0]);
  const [snackbar, setSnackbar] = useState({
    isOpen: false,
    text: '',
    type: 'success',
  });

  const [drawer, setDrawer] = useState({
    type: 'filters',
    isOpen: false,
    sheet: {},
  });

  const openSnackbar = (type, text) => setSnackbar({ isOpen: true, type, text });
  const closeSnackbar = () => setSnackbar((prev) => ({ ...prev, isOpen: false }));


  return (
    <>
      <ScheduleMobileHeader />
      <Grid
        container
        justifyContent={'center'}
        style={{ fontSize: 20,height: 60, margin: '20px 0' }}
      >
        <CarouselCalendar selectedDate={selectedDate} />
      </Grid>

      <Tabs
        value={activeTab.index}
        onChange={(event, newValue) => setActiveTab(TABS.find((t) => t.index === newValue))}
        className={classes.tabs}
      >
        {TABS.map((tab) => (
          <Tab
            key={tab.index}
            label={tab.label}
            href={tab.href}
            disableRipple
            component="a"
            onClick={(event) => event.preventDefault()}
          />
        ))}
      </Tabs>
      <Grid item xs={12}>
        <Divider />
      </Grid>
      {activeTab.index === 0 && <MySchedule openSnackbar={openSnackbar} />}
      {activeTab.index === 1 && <Resource openSnackbar={openSnackbar} />}
      <SnackBar closeSnackbar={closeSnackbar} {...snackbar} />


    </>
  );
};

export default withStyles(styles)(AdminMobileSchedulePage);
