import { combineReducers } from 'redux';
import { createReducer, createSlice } from '@reduxjs/toolkit';
import workerSchedulePageActions from './workerSchedulePageActions';

// Full Schedule
const initialState = {
  fullSchedule: {
    selectedDate: new Date(),
    sheets: [],
  },
  mySchedule: {
    selectedDate: new Date(),
    sheets: [],
  }
};
const fullSchedulePageReducer = createReducer(initialState.fullSchedule, {
  [workerSchedulePageActions.setFullScheduleDate]: (state, { payload }) => ({ ...state, selectedDate: payload }),
  [workerSchedulePageActions.getFullScheduleSheets]: (state, { payload }) => ({ ...state, sheets: payload }),
  [workerSchedulePageActions.addFullScheduleSheet]: (state, { payload }) => ({ ...state, sheets: [...state.sheets, payload] }),
  [workerSchedulePageActions.putFullScheduleSheet]: (state, { payload }) => ({
    ...state,
    sheets: state.sheets.map(sheet => sheet._id === payload._id ? payload : sheet),
  }),
  [workerSchedulePageActions.patchFullScheduleSheet]: (state, { payload }) => ({
    ...state,
    sheets: state.sheets.map(sheet => sheet._id === payload._id ? { ...sheet, ...payload } : sheet),
  }),
  [workerSchedulePageActions.deleteFullScheduleSheet]: (state, { payload }) => ({
    ...state,
    sheets: state.sheets.filter(sheet => sheet._id !== payload._id)
  }),
  [workerSchedulePageActions.patchFullScheduleSheetWorker]: (state, { payload }) => ({
    ...state,
    sheets: state.sheets.map(sheet => sheet._id === payload._id
        ? {
          ...sheet,
          workers: sheet.workers.map(worker => worker._id === payload.worker._id ? ({ ...worker, ...payload.worker }) : worker)
        }
        : sheet
    )
  }),
  [workerSchedulePageActions.patchFullScheduleWorker]: (state, { payload }) => ({
    ...state,
    sheets: state.sheets.map((sheet) => ({
      ...sheet,
      workers: sheet.workers.map(worker => worker._id === payload._id ? ({ ...worker, ...payload }) : worker),
    }))
  }),
  [workerSchedulePageActions.patchFullScheduleEquipment]: (state, { payload }) => ({
    ...state,
    sheets: state.sheets.map((sheet) => ({
      ...sheet,
      equipment: sheet.equipment.map(equip => equip._id === payload._id ? ({ ...equip, ...payload }) : equip),
    }))
  }),
})

// My Schedule
const mySchedulePageReducer = createReducer(initialState.mySchedule, {
  [workerSchedulePageActions.setMyScheduleDate]: (state, { payload }) => ({ ...state, selectedDate: payload }),
  [workerSchedulePageActions.getMyScheduleSheets]: (state, { payload }) => ({ ...state, sheets: payload }),
  [workerSchedulePageActions.addMyScheduleSheet]: (state, { payload }) => ({ ...state, sheets: [...state.sheets, payload] }),
  [workerSchedulePageActions.putMyScheduleSheet]: (state, { payload }) => ({
    ...state,
    sheets: state.sheets.map(sheet => sheet._id === payload._id ? payload : sheet),
  }),
  [workerSchedulePageActions.patchMyScheduleSheet]: (state, { payload }) => ({
    ...state,
    sheets: state.sheets.map(sheet => sheet._id === payload._id ? { ...sheet, ...payload } : sheet),
  }),
  [workerSchedulePageActions.deleteMyScheduleSheet]: (state, { payload }) => ({
    ...state,
    sheets: state.sheets.filter(sheet => sheet._id !== payload._id)
  }),
  [workerSchedulePageActions.patchMyScheduleSheetWorker]: (state, { payload }) => ({
    ...state,
    sheets: state.sheets.map(sheet => sheet._id === payload._id
        ? {
          ...sheet,
          workers: sheet.workers.map(worker => worker._id === payload.worker._id ? ({ ...worker, ...payload.worker }) : worker)
        }
        : sheet
    )
  }),
  [workerSchedulePageActions.patchMyScheduleWorker]: (state, { payload }) => ({
    ...state,
    sheets: state.sheets.map((sheet) => ({
      ...sheet,
      workers: sheet.workers.map(worker => worker._id === payload._id ? ({ ...worker, ...payload }) : worker),
    }))
  }),
  [workerSchedulePageActions.patchMyScheduleEquipment]: (state, { payload }) => ({
    ...state,
    sheets: state.sheets.map((sheet) => ({
      ...sheet,
      equipment: sheet.equipment.map(equip => equip._id === payload._id ? ({ ...equip, ...payload }) : equip),
    }))
  }),
})

// Loading slice
const loadingSlice = createSlice({
  name: 'loading',
  initialState: false,
  reducers: {
    [workerSchedulePageActions.setLoading]: (state, { payload }) => payload,
  }
});

// SnackBar slice
const snackBarSlice = createSlice({
  name: 'snackbar',
  initialState: null,
  reducers: {
    [workerSchedulePageActions.setSnackbar]: (state, { payload }) => payload,
    [workerSchedulePageActions.closeSnackbar]: () => null,
  }
});

export default combineReducers({
  mySchedule: mySchedulePageReducer,
  fullSchedule: fullSchedulePageReducer,
  loading: loadingSlice.reducer,
  snackbar: snackBarSlice.reducer,
});
