import { userTypes } from '../../helpers/userTypes';

export const info = {
  [userTypes.admin]: 'No Restrictions',
  [userTypes.fieldTechnician]:
    'View all Worklogs, Add and Edit Worklogs (Assigned to/Created by him). Add and Edit Notes, Use Kiosk Mode',
  [userTypes.accounting]:
    'View People, Schedule, Availability, Time Clock, Timeline. Use Kiosk Mode',
  [userTypes.dispatcher]:
    'Full Access to Worklog, Schedule, Notes, Projects, Settings, Time Clock, Timeline. View Basic User Info, Edit Toggles for Field Technicians, Use Kiosk Mode',
  [userTypes.fleetMaintenance]: 'View Schedule, Use Kiosk Mode, Edit CIP Projects',
  [userTypes.projectManagement]:
    'View Schedule, Availability. Full Access to Notes, Use Kiosk Mode.',
  [userTypes.machineShop]: 'Use Kiosk Mode',
};
