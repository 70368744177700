export const getHandleMouseMove = (scrollContainerRef) => {
  const scrollContainer = scrollContainerRef.current.calendar.el.querySelector('.fc-view.fc-timeGrid-view');
  const scrollSpeed = 4;

  let shouldDrag = true;

  const scrollHandler = (e) => {
    if (!scrollContainer || !shouldDrag) {
      return;
    }
  
    const rect = scrollContainer.getBoundingClientRect();
    let axisY = 0;
    let axisX = 0;
    
    const mouseY = e.clientY;
    const mouseX = e.clientX;

    // Y movement
    if (mouseY < rect.top + 50) axisY = -scrollSpeed;
    else if (mouseY > rect.bottom - 50) axisY = scrollSpeed;

    // X movement
    if (mouseX < rect.left + 50) axisX = -scrollSpeed;
    else if (mouseX > rect.right - 50) axisX = scrollSpeed;

    if (axisX || axisY) {
      scrollContainer.scrollBy(axisX, axisY);
    }
  }
  
  const mouseDownHandler = (e) => {
    scrollHandler(e);

    if (shouldDrag) {
      shouldDrag = false;
      setTimeout(() => {
        shouldDrag = true;
      }, 10);
    }
  }


  return {
    init: () => {
      document.addEventListener('drag', mouseDownHandler);;
    },
    destroy: () => {
      document.removeEventListener('drag', mouseDownHandler);
    }
  };
};