import React from 'react';
import { format, addDays, subDays } from 'date-fns';
import classnames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import ArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import ArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import { GLOBAL_DATE_FORMAT } from 'common/dateFormatConfig';

import styles from './styles.js'

export const ArrowHeaderCalendar = ({ classes, selectedDate, setDate }) => {

  const updateDate = (type) => () => {
    let newDate = null;
    if (type === 'add') newDate = addDays(new Date(selectedDate), 1);
    if (type === 'sub') newDate = subDays(new Date(selectedDate), 1);
    setDate(newDate);
  };

  return (
    <Typography
      variant="h4"
      align="center"
      className={classnames(classes.regularFontWeight, classes.calendarWrapper)}
    >
      <ArrowLeftIcon onClick={updateDate('sub')} className={classes.inlineBlock} />
      <span
        className={classes.inlineBlock}
      >
        {format(new Date(selectedDate), GLOBAL_DATE_FORMAT.fullDateWithDay)}
      </span>
      <ArrowRightIcon onClick={updateDate('add')} className={classes.inlineBlock} />
    </Typography>
  )
}

export default withStyles(styles)(ArrowHeaderCalendar);