import { getOptions } from 'helpers/getOptions';
import $api from 'http/index';

export const authorizeToDocuSign = async () => {
  try {
    await $api.get(`${process.env.REACT_APP_BASE_URL}/docusign/check-status`, getOptions());
  } catch (error) {
    throw new Error(error.message);
  }
};
