import { useState, useRef, useEffect } from 'react';
import { withStyles } from '@material-ui/core/styles';
import styles from './styles';
import { Navigation, Pagination, Scrollbar, A11y } from 'swiper/modules';
import { startOfWeek, addDays, isSameDay } from 'date-fns';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import { format } from 'date-fns-tz';
import WeekItem from './WeekItem';
import { useWeekStart } from 'hooks/useWeekStart';
import { GLOBAL_DATE_FORMAT } from 'common/dateFormatConfig';

const getDaysOfCurrentWeek = (weekDayFromO, previousWeeksCount = 30, nextWeeksCount = 30) => {
  const today = new Date();
  const startOfCurrentWeek = startOfWeek(today, { weekStartsOn: weekDayFromO });

  const getDayInfo = (date) => {
    return {
      date: date, // date obj
      formatDay: format(date, GLOBAL_DATE_FORMAT.dayContraction), // format Sun = S
      dayNum: date.getDate(), // date num
    };
  };

  const getWeekDates = (startDay) => {
    const days = [];

    for (let i = 0; i < 7; i++) {
      const currentDate = addDays(startDay, i);
      days.push(getDayInfo(currentDate));
    }

    return days;
  };

  const weeks = [];

  for (let i = previousWeeksCount; i >= 1; i--) {
    const startOfPreviousWeek = addDays(startOfCurrentWeek, -i * 7);
    weeks.push(getWeekDates(startOfPreviousWeek));
  }

  weeks.push(getWeekDates(startOfCurrentWeek));

  for (let i = 1; i <= nextWeeksCount; i++) {
    const startOfNextWeek = addDays(startOfCurrentWeek, i * 7);
    weeks.push(getWeekDates(startOfNextWeek));
  }

  return weeks;
};

const findDateWeek = (formattedWeeks, findDate, selectOnCalendar) => {
  let foundInWeekIndex = -1;

  formattedWeeks.some((week, index) => {
    let selectedDay = {};
    const found = week.some((day) => {
      if (isSameDay(day.date, findDate)) {
        selectedDay = day.date;
        return true;
      } else {
        return false;
      }
    });

    if (found) {
      selectOnCalendar(selectedDay);
      foundInWeekIndex = index;
      return true;
    }
    return false;
  });
  return foundInWeekIndex;
};

const addAdjacentWeek = (weekArray, isNextWeek) => {
  const lastDate = weekArray[weekArray.length - 1].date;
  const direction = isNextWeek ? 1 : -1;

  const newWeek = [];
  for (let i = 1; i <= 7; i++) {
    const currentDate = addDays(new Date(lastDate), i * direction);
    const formatDay = format(currentDate, GLOBAL_DATE_FORMAT.dayContraction);
    const dayNum = currentDate.getDate();

    newWeek.push({
      date: currentDate,
      formatDay: formatDay,
      dayNum: dayNum,
    });
  }

  return newWeek;
};

const CarouselCalendar = ({ classes, selectedDate, onDateSelected }) => {
  const swiperRef = useRef(null);
  // const selectedDate = useSelector((state) => state.schedule.selectedDate);
  const { weekDayFromO } = useWeekStart();

  const openTodaySlide = (slideIndex) => {
    if (swiperRef && swiperRef.current) {
      swiperRef.current.swiper.slideTo(slideIndex); // go to some index slide
    }
  };

  const [allWeeks, setAllWeeks] = useState(getDaysOfCurrentWeek(weekDayFromO));

  const [selectedCarouselDate, setSelectedCarouselDate] = useState(selectedDate);

  useEffect(() => {
    openTodaySlide(
      findDateWeek(getDaysOfCurrentWeek(weekDayFromO), selectedDate, setSelectedCarouselDate)
    );
  }, [selectedDate]);

  useEffect(() => {
    setAllWeeks(getDaysOfCurrentWeek(weekDayFromO));
  }, [weekDayFromO]);

  const handleSelect = (date) => {
    setSelectedCarouselDate(date);

    if (onDateSelected) {
      onDateSelected(date);
    }
  }

  return (
    <>
      <Swiper
        ref={swiperRef}
        slidesPerView={1}
        className="mySwiper"
        modules={[Navigation, Pagination, Scrollbar, A11y]}
        // onSwiper={(swiper) => {}}
        onSlideChange={(i) => {}}
        onReachBeginning={() => {}}
        onReachEnd={() => {}}
      >
        {allWeeks.map((weekDayArr, i) => (
          <SwiperSlide key={i}>
            <WeekItem
              weekItem={weekDayArr}
              selectedCarouselDate={selectedCarouselDate}
              setSelectedCarouselDate={handleSelect}
            />
          </SwiperSlide>
        ))}
      </Swiper>
    </>
  );
};

export default withStyles(styles)(CarouselCalendar);
