import { userTypes } from './userTypes';

export const rolePermissions = {
  [userTypes.admin]: [
    'cipKioskMode',
    'editPTO',
    'activeSchedule',
    'HolidayOvertimeException',
    'PTOOvertimeException',
    'remoteKiosk',
    'kioskModeMobile',
    'payrollActions',
  ],
  [userTypes.fieldTechnician]: [
    'cipKioskMode',
    'activeSchedule',
    'HolidayOvertimeException',
    'PTOOvertimeException',
    'remoteKiosk',
    'kioskModeMobile',
  ],
  [userTypes.accounting]: [
    'projectsRead',
    'equipmentRead',
    'availabilityFullAccess',
    'timeClockFullAccess',
    'cipFullAccess',
    'usersFullAccess',
    'editPTO',
    'worklogsRead',
    'activeSchedule',
    'projectsFullAccess',
    'kioskMode',
    'holidaysCreate',
    'HolidayOvertimeException',
    'PTOOvertimeException',
    'remoteKiosk',
    'kioskModeMobile',
    'payrollActions',
  ],
  [userTypes.fleetMaintenance]: [
    'cipKioskMode',
    'equipmentFullAccess',
    'availabilityRead',
    'availabilityEquipmentEdit',
    'cipFullAccess',
    'activeSchedule',
    'HolidayOvertimeException',
    'PTOOvertimeException',
    'remoteKiosk',
    'kioskModeMobile',
  ],
  [userTypes.projectManagement]: [
    'worklogsEdit',
    'worklogsEditSubmitted',
    'projectsFullAccess',
    'activeSchedule',
    'scheduleFullAccess',
    'suppliesFullAccess',
    'HolidayOvertimeException',
    'PTOOvertimeException',
    'remoteKiosk',
    'kioskModeMobile',
  ],
  [userTypes.dispatcher]: [
    'editPTO',
    'activeSchedule',
    'availabilityEquipmentEdit',
    'availabilityPeopleEdit',
    'suppliesFullAccess',
    'HolidayOvertimeException',
    'PTOOvertimeException',
    'remoteKiosk',
    'kioskModeMobile',
  ],
  [userTypes.machineShop]: [
    'cipKioskMode',
    'activeSchedule',
    'HolidayOvertimeException',
    'PTOOvertimeException',
    'remoteKiosk',
    'kioskModeMobile',
  ],
};
