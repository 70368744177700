import React, { useCallback } from 'react';
import propTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Divider from '@material-ui/core/Divider';
import CloseIcon from '@material-ui/icons/Close';
import IconSunny from 'assets/images/icon-sunny.svg';
import IconMoon from 'assets/images/icon-moon.svg';
import HICodes from 'components/HICodes/HICodes'
import {
  postHICodesByTenant,
  putHICodesByTenant,
  deleteHICodesByTenant,
} from 'store/superadmin/superadminOperations';

import styles from './styles';
import { isIOS, Option } from 'components/Option';
import { createOptions } from 'helpers/createOptions';
import { useTimeStates } from 'hooks/useTimeStates';
import { useTimeSettingsFormat } from 'common/useTimeSettingsFormat';

const UserDetails = ({
  classes,
  values,
  errors,
  touched,
  handleChange,
  handleBlur,
  clientsData,
  setFieldValue,
  openSnackbar
}) => {
  const dispatch = useDispatch();
  const { hiCodes, loading: hiCodesLoading } = useSelector(state => state.superadmin.hiCodesByTenant);

  const { hours, minutes, dayPart } = useTimeStates({ takeSettingsHours: true });
  const getTimeFormatHook = useTimeSettingsFormat();

  const handleEmploymentStatusChange = (e) => {
    return handleChange({
      target: {
        name: 'profile.shifts.timeOfDay',
        value: +e.target.value ? 'Day' : 'Inactive',
      },
    });
  };

  const handleClearIndividualHours = (e) => {
    handleChange({ target: { name: 'profile.individualStartTime.hours', value: null } });
    handleChange({ target: { name: 'profile.individualStartTime.minutes', value: null } });
    handleChange({ target: { name: 'profile.individualStartTime.amPm', value: null } });
  };

  const createHICodeAction = useCallback((input) => dispatch(postHICodesByTenant(values?.organizationId, input)), [values?.organizationId])
  const updateHICodeAction = useCallback((input) => dispatch(putHICodesByTenant(values?.organizationId, input)), [values?.organizationId])
  const deleteHICodeAction = useCallback((input) => dispatch(deleteHICodesByTenant(values?.organizationId, input)), [values?.organizationId])

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6} lg={4}>
          <label htmlFor="username">
            <Typography variant="body1" color="textSecondary">
              User name<span className={classes.requiredSign}>*</span>:
            </Typography>
            <TextField
              type="text"
              id="username"
              name="username"
              value={values.username}
              onChange={handleChange}
              error={Boolean(errors.username) && Boolean(touched.username)}
              onBlur={handleBlur}
              className={classes.textField}
              variant="outlined"
              inputProps={{ 'aria-label': 'Username' }}
              fullWidth
            />
            {errors.username && touched.username ? (
              <div className={classes.error}>{errors.username}</div>
            ) : null}
          </label>
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
          <label htmlFor="profile.fullName">
            <Typography variant="body1" color="textSecondary">
              Full Name<span className={classes.requiredSign}>*</span>:
            </Typography>
            <TextField
              type="text"
              id="profile.fullName"
              name="profile.fullName"
              value={values.profile.fullName}
              onChange={handleChange}
              error={Boolean(errors.fullName) && Boolean(touched.profile?.fullName)}
              onBlur={handleBlur}
              className={classes.textField}
              variant="outlined"
              inputProps={{ 'aria-label': 'Full Name' }}
              fullWidth
            />
            {errors.fullName && touched.profile?.fullName ? (
              <div className={classes.error}>{errors.fullName}</div>
            ) : null}
          </label>
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
          <label htmlFor="profile.email">
            <Typography variant="body1" color="textSecondary">
              Email<span className={classes.requiredSign}>*</span>:
            </Typography>
            <TextField
              type="text"
              id="profile.email"
              autoComplete="false"
              value={values.profile.email}
              error={Boolean(errors.email) && Boolean(touched.profile?.email)}
              onBlur={handleBlur}
              onChange={handleChange}
              className={classes.textField}
              variant="outlined"
              inputProps={{ 'aria-label': 'Email' }}
              fullWidth
            />
            {errors.email && touched.profile?.email ? (
              <div className={classes.error}>{errors.email}</div>
            ) : null}
          </label>
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
          <label htmlFor="profile.phoneNumber">
            <Typography variant="body1" color="textSecondary">
              Phone Number:
            </Typography>
            <TextField
              type="text"
              id="profile.phoneNumber"
              name="profile.phoneNumber"
              value={values.profile.phoneNumber}
              error={Boolean(errors.phoneNumber) && Boolean(touched.profile?.phoneNumber)}
              onBlur={handleBlur}
              onChange={handleChange}
              className={classes.textField}
              variant="outlined"
              inputProps={{ 'aria-label': 'Phone number' }}
              fullWidth
            />
            {errors.phoneNumber && touched.profile?.phoneNumber ? (
              <div className={classes.error}>{errors.phoneNumber}</div>
            ) : null}
          </label>
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
          <label htmlFor="organizationId">
            <Typography variant="body1" color="textSecondary">
              Company name:
            </Typography>
            <TextField
              select
              id="organizationId"
              name="organizationId"
              value={values.organizationId}
              error={Boolean(errors.organizationId) && Boolean(touched.organizationId)}
              onBlur={handleBlur}
              onChange={handleChange}
              className={classes.textField}
              variant="outlined"
              inputProps={{ 'aria-label': 'Company Name' }}
              fullWidth
            >
              {clientsData.map((client) => {
                return (
                  <Option key={client._id} value={client._id}>
                    {client.name}
                  </Option>
                );
              })}
            </TextField>
            {errors.organizationId && touched.organizationId ? (
              <div className={classes.error}>{errors.organizationId}</div>
            ) : null}
          </label>
        </Grid>
      </Grid>
      <Divider className={classes.marginVertical} />
      <Grid container spacing={2}>
        <Grid item xs={12} md={6} lg={4}>
          <label htmlFor="profile.employeeNum">
            <Typography variant="body1" color="textSecondary">
              Employee Number<span className={classes.requiredSign}>*</span>:
            </Typography>
            <TextField
              type="text"
              id="profile.employeeNum"
              value={values.profile.employeeNum}
              onChange={handleChange}
              error={Boolean(errors.employeeNum) && Boolean(touched.profile?.employeeNum)}
              onBlur={handleBlur}
              className={classes.textField}
              variant="outlined"
              inputProps={{ 'aria-label': 'Employee number' }}
              fullWidth
            />
            {errors.employeeNum && touched.profile?.employeeNum ? (
              <div className={classes.error}>{errors.employeeNum}</div>
            ) : null}
          </label>
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
          <label htmlFor="HICode">
            <Typography variant="body1" color="textSecondary">
              Health Insurance Code<span className={classes.requiredSign}>*</span>:
            </Typography>
            <HICodes
              value={values?.profile?.HICode || ''}
              changeValue={(code) => setFieldValue('profile.HICode', code)}
              handleBlur={handleBlur}
              errors={errors}
              touched={touched}
              openSnackbar={openSnackbar}
              options={hiCodes[values?.organizationId] ?? []}
              loading={hiCodesLoading}
              disabled={!values?.organizationId}
              createAction={createHICodeAction}
              editAction={updateHICodeAction}
              deleteAction={deleteHICodeAction}
            />
          </label>
          {errors.HICode && touched.profile?.HICode ? (
            <div className={classes.error}>{errors.HICode}</div>
          ) : null}
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
          <label htmlFor="profile.shifts.timeOfDay">
            <Typography variant="body1" color="textSecondary">
              Shift:
            </Typography>
            <TextField
              select
              id="profile.shifts.timeOfDay"
              name="profile.shifts.timeOfDay"
              value={
                values.profile.shifts.timeOfDay === 'Inactive'
                  ? 'Day'
                  : values.profile.shifts.timeOfDay
              }
              onChange={handleChange}
              className={classes.textField}
              variant="outlined"
              inputProps={{ 'aria-label': 'Shift' }}
              fullWidth
              SelectProps={{
                native: isIOS,
              }}
              disabled={values.profile?.shifts?.timeOfDay === 'Inactive'}
            >
              <Option value="Day">
                {isIOS ? '🌤 ' : <img src={IconSunny} alt="sunny" className={classes.shiftImage} />}
                Day
              </Option>
              <Option value="Night">
                {isIOS ? '🌙 ' : <img src={IconMoon} alt="moon" className={classes.shiftImage} />}
                Night
              </Option>
            </TextField>
          </label>
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
          <label htmlFor="profile.shifts.weekDays">
            <Typography variant="body1" color="textSecondary">
              Days:
            </Typography>
            <TextField
              select
              id="profile.shifts.weekDays"
              name="profile.shifts.weekDays"
              value={values.profile.shifts.weekDays}
              onChange={handleChange}
              className={classes.textField}
              variant="outlined"
              inputProps={{ 'aria-label': 'Days' }}
              SelectProps={{
                native: isIOS,
              }}
              fullWidth
            >
              <Option value="Mon-Fri">Mon &mdash; Fri</Option>
              <Option value="Sun-Thu">Sun &mdash; Thu</Option>
              <Option value="Tue-Sat">Tue &mdash; Sat</Option>
            </TextField>
          </label>
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
          <label htmlFor="profile.timeoff.allowedTime">
            <Typography variant="body1" color="textSecondary">
              PTO Hours Per Year:
            </Typography>
            <TextField
              type="text"
              id="profile.timeoff.allowedTime"
              value={values.profile?.timeoff?.allowedTime}
              onChange={handleChange}
              error={Boolean(errors.allowedTime) && Boolean(touched.profile?.timeoff?.allowedTime)}
              onBlur={handleBlur}
              className={classes.textField}
              variant="outlined"
              inputProps={{ 'aria-label': 'PTO hours per year' }}
              fullWidth
            />
            {errors.allowedTime && touched.profile?.timeoff?.allowedTime ? (
              <div className={classes.error}>{errors.allowedTime}</div>
            ) : null}
          </label>
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
          <label htmlFor={'hours'} className={classes.label}>
            <Typography variant="body1" color="textSecondary">
              Individual Start Time:
            </Typography>
            <div className={classes.wrapper}>
              <TextField
                select
                id="profile.individualStartTime.hours"
                variant="outlined"
                value={values.profile?.individualStartTime?.hours || ''}
                onChange={(e) => {
                  handleChange({
                    target: {
                      name: 'profile.individualStartTime.hours',
                      value: `${e.target.value}`,
                    },
                  });
                }}
                className={classes.select}
                SelectProps={{
                  native: isIOS,
                }}
              >
                {hours.map(createOptions)}
              </TextField>
              <div variant="body1" className={classes.delimiter}>
                :
              </div>
              <TextField
                select
                id="profile.individualStartTime.minutes"
                variant="outlined"
                value={values.profile?.individualStartTime?.minutes}
                className={classes.select}
                onChange={(e) => {
                  handleChange({
                    target: {
                      name: 'profile.individualStartTime.minutes',
                      value: `${e.target.value}`,
                    },
                  });
                }}
                SelectProps={{
                  native: isIOS,
                }}
              >
                {minutes.map(createOptions)}
              </TextField>
              {getTimeFormatHook().is12Format && (
                <TextField
                  select
                  id="profile.individualStartTime.amPm"
                  variant="outlined"
                  value={values.profile?.individualStartTime?.amPm}
                  className={classes.select}
                  onChange={(e) => {
                    handleChange({
                      target: {
                        name: 'profile.individualStartTime.amPm',
                        value: `${e.target.value}`,
                      },
                    });
                  }}
                  SelectProps={{
                    native: isIOS,
                  }}
                >
                  {dayPart.map(createOptions)}
                </TextField>
              )}
              <CloseIcon className={classes.clearSelect} onClick={handleClearIndividualHours} />
            </div>
          </label>
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
          <label htmlFor="employmentStatus">
            <Typography variant="body1" color="textSecondary">
              Employment Status:
            </Typography>
            <TextField
              select
              type="text"
              id="employmentStatus"
              value={Number(values.profile?.shifts?.timeOfDay !== 'Inactive')}
              onChange={handleEmploymentStatusChange}
              className={classes.textField}
              variant="outlined"
              inputProps={{ 'aria-label': 'Employment Status' }}
              SelectProps={{
                native: isIOS,
              }}
              fullWidth
            >
              <Option value={1}>Active</Option>
              <Option value={0}>Inactive</Option>
            </TextField>
          </label>
        </Grid>
      </Grid>

      {!!values.profile?.emergencyContacts?.length && (
        <>
          <Divider xs={9} className={'settings-devider'} />
          <Typography variant="h5" style={{ marginBottom: '25px' }}>
            Emergency contacts
          </Typography>
          {values.profile.emergencyContacts.map((el, i) => (
            <div key={el.id} className={'emergency-block'}>
              <Typography variant="h6">{el.relationship}</Typography>
              <Grid container spacing={2} alignItems={'center'}>
                <Grid item xs={12} md={6} lg={4}>
                  <label htmlFor={`emergencyContacts[${i}].name`}>
                    <Typography variant="body1" color="textSecondary">
                      Name:
                    </Typography>
                    <TextField
                      type="text"
                      id={`emergencyContacts[${i}].name`}
                      value={values.profile.emergencyContacts[i].name}
                      disabled
                      onBlur={handleBlur}
                      className={classes.textField}
                      variant="outlined"
                      inputProps={{ 'aria-label': 'Name' }}
                      fullWidth
                    />
                  </label>
                </Grid>

                <Grid item xs={12} md={6} lg={4}>
                  <label htmlFor={`values.profile.emergencyContacts[${i}].phoneNumber`}>
                    <Typography variant="body1" color="textSecondary">
                      Phone number:
                    </Typography>
                    <TextField
                      type="text"
                      id={`values.profile.emergencyContacts[${i}].phoneNumber`}
                      value={values.profile.emergencyContacts[i].phoneNumber}
                      disabled
                      onBlur={handleBlur}
                      className={classes.textField}
                      variant="outlined"
                      inputProps={{ 'aria-label': 'Phone number' }}
                      fullWidth
                    />
                  </label>
                </Grid>

                <Grid item xs={12} md={6} lg={4}>
                  <label htmlFor={`values.profile.emergencyContacts[${i}].address`}>
                    <Typography variant="body1" color="textSecondary">
                      Address:
                    </Typography>
                    <TextField
                      type="text"
                      disabled
                      id={`values.profile.emergencyContacts[${i}].address`}
                      value={values.profile.emergencyContacts[i].address}
                      onBlur={handleBlur}
                      className={classes.textField}
                      variant="outlined"
                      inputProps={{ 'aria-label': 'Address' }}
                      fullWidth
                    />
                  </label>
                </Grid>
              </Grid>
            </div>
          ))}
        </>
      )}
    </>
  );
};

UserDetails.propTypes = {
  classes: propTypes.object.isRequired,
  values: propTypes.object.isRequired,
  errors: propTypes.object.isRequired,
  touched: propTypes.object.isRequired,
  handleChange: propTypes.func.isRequired,
  handleBlur: propTypes.func.isRequired,
};

export default withStyles(styles)(UserDetails);
