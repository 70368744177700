import React, { memo, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import LoadingSpinner from '../../../../components/LoadingSpinner';
import WorklogsList from '../WorklogsList';
import { fetchMyScheduleSheets } from 'store/workerSchedule/workerSchedulePageOperations';
import workerSchedulePageActions from 'store/workerSchedule/workerSchedulePageActions';

const MySchedule = memo(
  ({openSnackbar}) => {
    const dispatch = useDispatch();
    const { loading, mySchedule: { sheets, selectedDate } } = useSelector(state => state.workerSchedule);

    useEffect(() => {
      const getData = async () =>{
        dispatch(workerSchedulePageActions.setLoading(true));
        try {
          dispatch(fetchMyScheduleSheets(selectedDate));
        } catch (e) {
          openSnackbar('error', e.message);
        } finally {
          dispatch(workerSchedulePageActions.setLoading(false));
        }
      }
      getData()
    }, [selectedDate]);

    const setDate = (date) => dispatch(workerSchedulePageActions.setMyScheduleDate(date));

    return (
      <div>
        {loading ? (
          <LoadingSpinner height={40} margin={32} />
        ) : (
          <WorklogsList
            sheets={sheets}
            isFullSchedule={false}
            openSnackbar={openSnackbar}
            setDate={setDate}
            selectedDate={selectedDate}
          />
        )}
      </div>
    );
  },
  (prevProps, nextProps) => prevProps.loading === nextProps.loading
);

export default MySchedule;