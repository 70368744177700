import { createAction } from '@reduxjs/toolkit';

const setPayrollVerified = createAction('timeclocks/setPayrollVerified');
const setPayrollReconciled = createAction('timeclocks/setPayrollReconciled');
const setTabs = createAction('timeclocks/setTabs');
const setActiveTab = createAction('timeclocks/setActiveTab');
const setLoading = createAction('timeclocks/setLoading');
const setSummary = createAction('timeclocks/setSummary');
const setWeekSummary = createAction('timeclocks/setWeekSummary');
const setUser = createAction('timeclocks/setUser');
const setUsers = createAction('timeclocks/setUsers');
const setAllUsers = createAction('timeclocks/setAllUsers');
const reset = createAction('timeclocks/reset');
const setCurrentPage = createAction('timeclocks/setCurrentPage');
const removeTimeclock = createAction('timeclocks/removeTimeclock');
const removeSnackbar = createAction('timeclocks/removeSnackbar');
const enqueueSnackbar = createAction('timeclocks/enqueueSnackbar');
const setExcelLoading = createAction('timeclocks/setExcelLoading');
const dis = createAction('timeclocks/dis');

export default {
  setPayrollVerified,
  setPayrollReconciled,
  setTabs,
  setActiveTab,
  setLoading,
  setSummary,
  setWeekSummary,
  setUser,
  setUsers,
  setAllUsers,
  reset,
  setCurrentPage,
  removeTimeclock,
  removeSnackbar,
  enqueueSnackbar,
  setExcelLoading,
  dis
};
